import React from 'react'
import styled from 'styled-components'

import { ImageDataLike } from 'gatsby-plugin-image'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import { Text } from 'components/shared/typography'

type Tile = {
  link: string
  img: ImageDataLike
  alt: string
  label: string
}

type Props = {
  tiles: Tile[]
}

const Section = styled.section`
  padding-bottom: 50px;

  ${({ theme }) => theme.media.lg.min} {
    padding-bottom: 80px;
  }
`

const TilesWrapper = styled.div`
  max-width: 680px;
  margin: 0 auto;

  ${({ theme }) => theme.media.md.min} {
    margin-top: 20px;
  }

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    max-width: unset;
    width: 100%;
    gap: 40px;
  }

  ${({ theme }) => theme.media.xxxl.min} {
    gap: 50px;
  }
`

const TileOverlay = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: 150ms ease-in-out opacity;

  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgb(0 0 0 / 80%) 100%
  );
`

const TileLink = styled.a`
  position: relative;
  display: block;
  margin-bottom: 25px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
  }

  &:hover {
    ${TileOverlay} {
      opacity: 0.15;
    }
  }
`

const BadgeWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 0;
  transform: translateY(-50%);
  max-width: max-content;
  padding: 20px 20px 20px 15px;
  background-color: #0000007a;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 1;

  ${({ theme }) => theme.media.xxxl.min} {
    ${Text} {
      font-size: 18px;
    }
  }
`

const Tiles: React.FC<Props> = ({ tiles }) => {
  return (
    <Section>
      <Container>
        <TilesWrapper>
          {tiles?.map(({ link, img, alt, label }, index) => (
            <TileLink key={`tile-${index}`} href={link}>
              <LazyImage src={img} alt={alt} />
              <BadgeWrapper>
                <Text
                  size={14}
                  weight={600}
                  themecolor="white"
                  margin="0"
                  align="left"
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              </BadgeWrapper>
              <TileOverlay />
            </TileLink>
          ))}
        </TilesWrapper>
      </Container>
    </Section>
  )
}

export default Tiles
