import React from 'react'
import styled from 'styled-components'
import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  description: string
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0;

  ${({ theme }) => theme.media.lg.min} {
    padding: 0 0 40px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledText = styled(Text)`
  position: relative;
  padding-left: 30px;

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

const About: React.FC<Props> = ({ description }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <Container>
        <StyledText
          size={lg ? 16 : 14}
          weight={500}
          themecolor="black"
          align="left"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Container>
    </Section>
  )
}

export default About
